import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loadable from "react-loadable";

import AppPrivateRoute from "./AppPrivateRoute";
import AppReferenceRoute from "./AppReferenceRoute";
import Layout from "./components/Layout";

import HOC_loading_2 from "./HOC/HOC_loading_2";
import HOC_loading from "./HOC/HOC_loading";

import { getAuthState, syncTime } from "./redux/actions/action_login";
import { selectLang } from "./redux/actions/action_language";
import { onSnapShotMyCoupon } from "./redux/actions/action_mycoupons";

import { removeCookie } from "./libs/cookie";

import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";

const Loading = HOC_loading(() => <div></div>);
const Login = Loadable({
  loader: () => import("./pages/Login"),
  loading: Loading,
});
const LIFF = Loadable({
  loader: () => import("./pages/LIFF"),
  loading: Loading,
});
const BusinessList = Loadable({
  loader: () => import("./pages/BusinessList"),
  loading: Loading,
});
const Rewards = Loadable({
  loader: () => import("./pages/Rewards"),
  loading: Loading,
});
const Profile = Loadable({
  loader: () => import("./pages/Profile"),
  loading: Loading,
});
const EditProfile = Loadable({
  loader: () => import("./pages/EditProfile"),
  loading: Loading,
});
const PointHistory = Loadable({
  loader: () => import("./pages/PointHistory"),
  loading: Loading,
});
const QrScan = Loadable({
  loader: () => import("./pages/QrScan"),
  loading: Loading,
});
const Register = Loadable({
  loader: () => import("./pages/Register"),
  loading: Loading,
});
const EarnPoints = Loadable({
  loader: () => import("./pages/EarnPoints"),
  loading: Loading,
});
const FilterPage = Loadable({
  loader: () => import("./pages/FilterPage"),
  loading: Loading,
});
const MyCoupons = Loadable({
  loader: () => import("./pages/MyCoupons"),
  loading: Loading,
});
const Business = Loadable({
  loader: () => import("./pages/Business"),
  loading: Loading,
});
const TermsOfService = Loadable({
  loader: () => import("./pages/Terms"),
  loading: Loading,
});
const PrivacyPolicy = Loadable({
  loader: () => import("./pages/PrivacyPolicy"),
  loading: Loading,
});
const Setting = Loadable({
  loader: () => import("./pages/Setting"),
  loading: Loading,
});
const SettingBusiness = Loadable({
  loader: () => import("./pages/Setting/setting_business"),
  loading: Loading,
});
const SettingLanguage = Loadable({
  loader: () => import("./pages/Setting/setting_language"),
  loading: Loading,
});
const CardProfileQR = Loadable({
  loader: () => import("./pages/Profile/ProfileCardQR"),
  loading: Loading,
});
const ChangePhoneNumber = Loadable({
  loader: () => import("./pages/Profile/ChangePhoneNumber"),
  loading: Loading,
});
const ErrorPage = Loadable({
  loader: () => import("./pages/Error"),
  loading: Loading,
});
const Authenticate = Loadable({
  loader: () => import("./pages/Authenticate"),
  loading: Loading,
});
const Address = Loadable({
  loader: () => import("./pages/Address"),
  loading: Loading,
});
const MyCards = Loadable({
  loader: () => import("./pages/MyCards"),
  loading: Loading,
});
const BeforeVerifyEmail = Loadable({
  loader: () => import("./pages/BeforeVerifyEmail/BeforeVerifyEmail"),
  loading: Loading,
});
const CollectPoint = Loadable({
  loader: () => import("./pages/CollectPoint/CollectPoint"),
  loading: Loading,
});
const TransferPage = Loadable({
  loader: () => import("./pages/Transfer"),
  loading: Loading,
});
const MyBusinessCoupons = Loadable({
  loader: () => import("./pages/MyBusinessCoupons"),
  loading: Loading,
});
const SettingNotification = Loadable({
  loader: () => import("./pages/Setting/setting_notification"),
  loading: Loading,
});
const AllRewardsList = Loadable({
  loader: () => import("./pages/AllRewards"),
  loading: Loading,
});

const ResetPhoneNumber = Loadable({
  loader: () => import("./pages/ResetPhoneNumber"),
  loading: Loading,
});

const LineSetting = Loadable({
  loader: () => import("./pages/Setting/setting_line"),
  loading: Loading,
});

const MemberCard = Loadable({
  loader: () => import("./pages/MemberCard"),
  loading: Loading,
});

class AppRoute extends Component {
  async componentDidMount() {
    if (window.location.href.indexOf("liff") > -1) {
      Object.assign(window.ps, { uid: null });
      removeCookie("__s");
      removeCookie("__a");
      removeCookie("__cid");
    }
    await this.initial();
  }

  async initial() {
    this.props.selectLang();
    await Promise.all([
      new Promise((resolve, reject) =>
        this.props.getAuthState((err, data) => resolve(data))
      ),
      new Promise((resolve, reject) =>
        this.props.syncTime((err, data) => resolve(data))
      ),
    ]);
  }

  componentDidUpdate(prevProps) {
    let prevBadgeCode = prevProps.store_profile.memberBadgeCode;
    let nextBadgeCode = this.props.store_profile.memberBadgeCode;

    if (prevBadgeCode !== nextBadgeCode)
      this.props.onSnapShotMyCoupon(nextBadgeCode);
  }

  render() {
    let {
      store_language: { dictionary },
    } = this.props;
    let {
      store_permission: { isError, isLoading },
    } = this.props;

    return (
      <AppRouteWithLoading
        {...this.props}
        isError={isError}
        isLoading={isLoading}
        dictionary={dictionary}
      />
    );
  }
}

class MainRoute extends Component {
  render() {
    let { store_permission, store_business } = this.props;
    let business_code = store_business.business_code_from_subscribe;

    return (
      <Router>
        <Switch>
          <Route
            path="/login"
            render={(props) => {
              return !store_permission.auth ? (
                <Login {...props} />
              ) : !store_permission.register ? (
                <Redirect to="/register" />
              ) : store_permission.redirectPath ? (
                <Redirect to={{ pathname: store_permission.redirectPath }} />
              ) : (
                <Redirect to="/points" />
              );
            }}
          />

          <Route
            path="/register"
            render={(props) => {
              return !store_permission.auth ? (
                <Redirect to="/login" />
              ) : !store_permission.register ? (
                <Register {...props} />
              ) : store_business.business_code_from_subscribe ? (
                <Redirect to={`/points/${business_code}`} />
              ) : store_permission.redirectPath ? (
                <Redirect to={{ pathname: store_permission.redirectPath }} />
              ) : (
                <Redirect to="/points" />
              );
            }}
          />

          <Route
            path="/verifyemail"
            render={(props) => {
              return !store_permission.auth ? (
                <Redirect to="/register" />
              ) : (
                <VerifyEmail {...props} />
              );
            }}
          />

          <Route
            path="/oauth/:challengeCode/:callback"
            render={(props) => {
              return !store_permission.auth ? (
                <Login {...props} />
              ) : (
                <Authenticate {...props} />
              );
            }}
          />

          <Route
            path="/notification"
            render={() => {
              return !store_permission.auth ? (
                <Redirect to="/login" />
              ) : (
                <Layout>
                  <SettingNotification />
                </Layout>
              );
            }}
          />

          <Route path="/reset/:ref" component={ResetPhoneNumber} />

          <Route path="/liff/:id/:options?/:ref?" component={LIFF} />
          <Route path="/r/:ref" component={FilterPage} />
          <AppReferenceRoute path="/send/:ref" component={EarnPoints} />

          <Route path="/b/:id/:options?" component={Business} />
          <Route path="/business/:id/:options?" component={Business} />

          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/error/:error_code" component={ErrorPage} />
          {store_permission.ref ? (
            <Route path="/:ref" component={FilterPage} />
          ) : (
            <Layout>
              <Switch>
                <AppPrivateRoute
                  path="/points/:id/collect"
                  component={CollectPoint}
                />
                <AppPrivateRoute
                  path="/points/:id/mycoupons"
                  component={MyBusinessCoupons}
                />
                <AppPrivateRoute
                  path="/points/:id/transfer"
                  component={TransferPage}
                />
                <AppPrivateRoute
                  path="/points/:id/membercard"
                  component={MemberCard}
                />
                <AppPrivateRoute
                  path="/points/:id/rewards/:options?"
                  component={AllRewardsList}
                />
                <AppPrivateRoute
                  path="/points/:id/:options?"
                  component={Rewards}
                  homepage="true"
                />
                <AppPrivateRoute path="/points" component={BusinessList} />
                <AppPrivateRoute path="/history/:id" component={PointHistory} />
                <AppPrivateRoute path="/editprofile" component={EditProfile} />
                <AppPrivateRoute path="/address" component={Address} />
                <AppPrivateRoute
                  path="/changephone"
                  component={ChangePhoneNumber}
                />
                <AppPrivateRoute path="/profile" component={Profile} />
                <AppPrivateRoute path="/qr" component={QrScan} />
                <AppPrivateRoute path="/mycoupons" component={MyCoupons} />
                <AppPrivateRoute path="/setting" component={Setting} />
                <AppPrivateRoute
                  path="/settingbusiness"
                  component={SettingBusiness}
                />
                <AppPrivateRoute path="/language" component={SettingLanguage} />
                <AppPrivateRoute path="/profileqr" component={CardProfileQR} />
                <AppPrivateRoute path="/cards" component={MyCards} />
                <AppPrivateRoute path="/line" component={LineSetting} />

                <AppPrivateRoute
                  path="/beforeVerifyEmail"
                  component={BeforeVerifyEmail}
                />

                <Redirect to="/points" />
              </Switch>
            </Layout>
          )}
          <Redirect to="/login" />
        </Switch>
      </Router>
    );
  }
}

const AppRouteWithLoading = HOC_loading_2(MainRoute);

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_language: state.language,
    store_profile: state.profile,
    store_business: state.business,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      getAuthState,
      selectLang,
      syncTime,
      onSnapShotMyCoupon,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute);
